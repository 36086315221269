import React from "react"

const UploadErrorNotification = ({ fileName, message }) => (
  <div className="notification has-text-centered is-warning">
    {fileName && <span className="has-text-weight-bold">{fileName}</span>}{" "}
    {message}
  </div>
)

export default UploadErrorNotification
