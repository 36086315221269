import React, { Fragment } from "react"
import uploadGuidelines from "./utils/uploadGuidelines.json"
import Collapsible from "../Elements/Collapsible.js"

const UploadGuidelines = () => {
  return (
    <Fragment>
      <Collapsible
        className="has-background-primary"
        title="Upload Guidelines"
        color="light"
      >
        <ol>
          {uploadGuidelines.map((item, index) => (
            <li className="my-0" key={index}>
              {item}
            </li>
          ))}
        </ol>
      </Collapsible>
    </Fragment>
  )
}

export default UploadGuidelines
