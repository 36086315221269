import React from "react"
import ProofOfPaymentPreview from "./ProofOfPaymentPreview"
import ProofOfPaymentDropzone from "./ProofOfPaymentDropzone"

const UploadProofOfPayment = ({ proofOfPayment }) => {
  if (proofOfPayment?.path) return <ProofOfPaymentPreview />
  return <ProofOfPaymentDropzone />
}

export default UploadProofOfPayment
