import React from "react"

import Dropzone from "../../Upload/Dropzone"
import Section from "../../Elements/Section"

const ProofOfPaymentDropzone = () => {
  const MAX_FILE_SIZE_IN_BYTES = 3145728
  const MAX_FILE_COUNT = 1

  return (
    <Section>
      <Dropzone
        maxByteSize={MAX_FILE_SIZE_IN_BYTES}
        maxFileCount={MAX_FILE_COUNT}
        docType="PP"
      >
        Take a photo or choose a file
      </Dropzone>
    </Section>
  )
}

export default ProofOfPaymentDropzone
