import React, { Fragment, useContext } from "react"

import ActionButtons from "../Elements/ActionButtons"
import UploadGuidelines from "../Upload/UploadGuidelines"
import UploadProofOfPayment from "./ProofOfPayment/UploadProofOfPayment"

import { AppContext } from "../../context/AppContext"

const ProofOfPayment = ({ backRoute, nextRoute }) => {
  const { state } = useContext(AppContext)
  const { proofOfPayment } = state

  return (
    <Fragment>
      <UploadGuidelines />
      <UploadProofOfPayment proofOfPayment={proofOfPayment} />
      <ActionButtons
        next={{
          label: "Next: Summary",
          link: nextRoute ? nextRoute : "/epharmacy/summary",
        }}
        back={{
          label: "Back",
          link: backRoute || "/epharmacy/shipping-and-payment",
        }}
      />
    </Fragment>
  )
}

export default ProofOfPayment
