import React from "react"
import Layout from "../../components/Layout/Layout"
import Container from "../../components/Layout/Container"
import ProofOfPayment from "../../components/Epharmacy/ProofOfPayment"

import { GATSBY_HAS_SPECIAL_ORDER_ENABLED } from "gatsby-env-variables"

export default () => (
  <Layout
    title={
      <span>
        Upload Proof of Payment{" "}
        <span className="has-text-grey is-italic has-text-weight-normal">
          (Optional)
        </span>
      </span>
    }
    subtitle="Upload a scanned copy or screenshot of payment confirmation from your bank."
    process="epharmacy"
    seoTitle="Upload Proof of Payment"
    display={{ footer: false, helpCenterBanner: false }}
    underMaintenance={GATSBY_HAS_SPECIAL_ORDER_ENABLED === "disabled"}
  >
    <Container isCentered desktop={6} fullhd={6}>
      <ProofOfPayment
        backRoute="/special-order/shipping-and-payment"
        nextRoute="/special-order/summary"
      />
    </Container>
  </Layout>
)
