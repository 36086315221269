import React from "react"
import classNames from "classnames"

import styles from "../utils/upload.module.scss"

const UploadBox = ({ fileCount, children }) => {
  const blurb = fileCount
    ? "+ Add another photo or file"
    : "Take a photo or choose a file"
  return (
    <p
      className={classNames(
        "title my-1 is-size-4 has-text-primary has-text-centered",
        styles["dropzone__uploadBox"]
      )}
    >
      {children || blurb}
    </p>
  )
}

const DropzoneBody = ({
  filesUploaded,
  handleFileChooser,
  fileUploadSparkle,
  children,
}) => (
  <div
    className={classNames("is-flex mb-1", styles["dropzone__container"])}
    onClick={handleFileChooser}
    onKeyDown={handleFileChooser}
    role="button"
    tabIndex={0}
  >
    <UploadBox
      fileCount={filesUploaded.length}
      uploadIcon={fileUploadSparkle}
      children={children}
    />
  </div>
)

export default DropzoneBody
