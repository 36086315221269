import React, { useContext } from "react"
import Section from "../../Elements/Section"
import DocumentsCardRow from "../../Cart/DocumentsCard/DocumentsCardRow"

import { AppContext } from "../../../context/AppContext"

const ProofOfPaymentPreview = () => {
  const { state, dispatch } = useContext(AppContext)
  const { proofOfPayment } = state

  const handleDeleteDocument = () => {
    dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        proofOfPayment: "",
      },
    })
  }

  return (
    <Section>
      <DocumentsCardRow
        fileName={proofOfPayment.name}
        oldFileName={proofOfPayment.oldname}
        handleDeleteDocument={handleDeleteDocument}
        file={proofOfPayment}
      />
    </Section>
  )
}

export default ProofOfPaymentPreview
